const bannerData = {
    title: "矩阵获客",
    description:
        "针对品牌内容运营需求，提供团队分工、创作者对接、内容审核、数据统计等流程化解决方案，助力企业沉淀内容、轻松运营"
}

const serviceData = [
    {
        title: "项目管理",
        content:
            "通过项目打造标准化工作流，明晰成员协作职责，可配置预算保证项目正常运转，可视化数据轻松分析效果。",
        path: "/pages/operate/service/project.png"
    },
    {
        title: "任务管理",
        content: "将复杂工作拆分为细分任务，便于创作者快速理解、快速启动，便捷高效的进行任务管理。",
        path: "/pages/operate/service/task.png"
    },
    {
        title: "媒介管理",
        content: "平台拥有大量媒介资源，可以快速筛选，一键投放。批发模式，全网最低价。",
        path: "/pages/operate/service/media.png"
    },
    {
        title: "在线沟通",
        content:
            "平台支持一键直联创作者，在线与创作者沟通原创需求、投放排期、呈现方式等细节，投放需求直接跟创作者谈。",
        path: "/pages/operate/service/chat.png"
    },
    {
        title: "博主管理",
        content: "平台拥有大量博主、KOC、KOL资源，可以快速对接。",
        path: "/pages/operate/service/material.png"
    },
    {
        title: "效果分析",
        content: "不同维度、多种视图数据统计，项目效果一目了然，驱动企业持续优化",
        path: "/pages/operate/service/result.png"
    }
]

const getPath = (name : string) => `/pages/operate/sponsor/${name}.png`

const sponsorDataFirstLine = [
    "麦粒",
    "宝洁",
    "维盟",
    "康恩贝",
    "银曼",
    "松下",
    "台铃",
    "飞利浦",
    "佳能",
    "天梭",
    "微软",
    "qborn"
].map((item) => getPath(item))

const sponsorDataSecondLine = [
    "洪恩教育",
    "oiu",
    "pop-mart",
    "my-first-book",
    "chictoo",
    "澳佳宝",
    "维达",
    "乐蜗家纺",
    "贝氪",
    "东阿阿胶",
    "百脑汇",
    "文雅生物"
].map((item) => getPath(item))

const sponsorDataThirdLine = [
    "啄木鸟科技",
    "华一",
    "凯鑫丽白",
    "三毛游",
    "大立教育",
    "顺造",
    "两万年",
    "天鹅到家",
    "樊登读书",
    "小仙炖",
    "小罐茶",
].map((item) => getPath(item))

const targetData = [
    {
        title: "华一软件",
        tags: ["华一人力资源", "一卡通", "系统集成"],
        description:
            "深圳华一软件开发有限公司成立于2001年，是中国卓越的人力资源及“一卡通”管理系统提供商。我们结合品牌特色为华一打造【精准定位+爆文打造】的营销策略。助力品牌完成认知与转化的双赢，通过内容优化降低用户决策成本。",
        totalNum: "249w+",
        searchNum: "42%",
        path: "/pages/operate/target/huayi.png",
        siteUrl: "http://szcesoft.com/"
    },
    {
        title: "黑龙江初心饮品",
        tags: ["克东天然苏打水"],
        description:
            "黑龙江初心饮品有限公司成立于2019年，是以克东天然苏打水为主打产品，所以我们基于用户洞察，强调苏打水功能以及专业背书成为初心饮品构建多元内容矩阵的破局点。借助高互动的优质内容，有效撬动品牌自然流量的飙升。",
        totalNum: "439w+",
        searchNum: "89%",
        path: "/pages/operate/target/liangwannian.png",
        siteUrl: "http://www.liangwannian.cn/index.html"
    },
    {
        title: "ChainAI",
        tags: ["人工智能", "定制化AI"],
        description:
            "ChainAI是为全球终端用户提供更加个性化的 AI 体验。所以我们聚焦年轻群体，增强品牌定制化概念。以使用体验、模型训练来介绍产品功能卖点，打动用户，实现种草。",
        totalNum: "210w+",
        searchNum: "64%",
        path: "/pages/operate/target/ChainAI.png",
        siteUrl:"https://chainai.com/#intro"
    },
    {
        title: "大立教育",
        tags: ["建工培训航母", "消防工程师", "安全工程师"],
        description:
            "规划内容矩阵，覆盖用户数量最多的搜索引擎和垂直平台。知乎社区营销 ，知乎营销推广内容，单篇文案阅读量10万+，热门评论200+；成功获取众多高价值用户的关注。",
        totalNum: "563w+",
        searchNum: "139%",
        path: "/pages/operate/target/dalijiaoyu.png",
        siteUrl:"https://app.daliedu.cn/"
    },
    {
        title: "三毛信息科技",
        tags: ["境内旅游", "入境旅游", "研学旅行"],
        description:
            "广州三毛信息科技有限公司成立于2015年，是一家专注为景区/博物馆提供智慧旅游解决方案、输出文旅知识内容的高新互联网企业，所以我们根据细分产品受众打造差异化内容，精准用户痛点，实现巨额扩圈。",
        totalNum: "2630w+",
        searchNum: "420%",
        path: "/pages/operate/target/sanmaoyou.png",
        siteUrl:"https://hub.traveldaily.cn/company/148119"
    },
    {
        title: "啄木鸟科技",
        tags: ["技术开发", "网络服务", "平面创意","知识产权"],
        description:
            "泉州市啄木鸟网络科技有限公司（简称啄木鸟科技）成立于2014年3月。选取与品牌产品标签相符的小红书腰尾部达人及素人，使用产品直发图文内容。拉近品牌与消费者的距离，达成种草转化。",
        totalNum: "193w+",
        searchNum: "64%",
        path: "/pages/operate/target/zhuomuniaokeji.png",
        siteUrl:"https://www.aibro.com/"
    },
    {
        title: "北京奇妙印象科技",
        tags: ["塑料瓶检测设备", "罐内异物在线检测仪"],
        description:
            "采用新媒体全案营销的形式，通过双微、短视频、红书等平台全面曝光并传播。通过复制内容将多款产品打造成为爆品，提升用户品牌黏度。",
        totalNum: "6457w+",
        searchNum: "445%",
        path: "/pages/operate/target/miaoxiangkeji.png",
        siteUrl:"https://www.miaoxiangkeji.com"
    },
    {
        title: "济南悦颜康健生物科技",
        tags: ["凯鑫丽白祛斑霜", "悦佳秀丽", "科学抗老"],
        description:
            "我们通过打造官方账号，提高产品科学、科研配方等方面的产品背书，提升品牌信任度。结合素人账号分享产品真实测评，深度结合使用场景，提升用户感知度。",
        totalNum: "358w+",
        searchNum: "41%",
        path: "/pages/operate/target/yueyankangjian.png",
        siteUrl:"http://kaixinlibai.5588.tv/"
    },
    {
        title: "浙江水科文化集团",
        tags: ["考拉兔", "数字乡村", "智能景区"],
        description:
            "通过打造官方账号，提高产品科学、科研配方等方面的产品背书，提升品牌信任度。结合素人账号分享产品真实测评，深度结合使用场景，提升用户感知度。",
        totalNum: "412w+",
        searchNum: "65%",
        path: "/pages/operate/target/zhejiangshuike.png",
        siteUrl: "https://baike.baidu.com/item/%E6%B5%99%E6%B1%9F%E6%B0%B4%E7%A7%91%E6%96%87%E5%8C%96%E9%9B%86%E5%9B%A2%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/62624135?fr=ge_ala"
    }
]

export const homePageData = {
    bannerData,
    serviceData,
    sponsorDataFirstLine,
    sponsorDataSecondLine,
    sponsorDataThirdLine,
    targetData
}
  