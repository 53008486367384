import React from "react"
import './ServiceCard.scss'
const ServiceCard = ({ data, className }) => {
  return (
    <div className="card_box">
      {
        data?.map((v,i)=> 
        (
          <div key={i} className="card_content1">
            <img src={v.path} />
            <div className="service_title">{v.title}</div>
            <div className="service_des">{v.content}</div>
          </div>
        )
        )
      }
      <div style={{ height: '250px', width:'1px' }}></div>
    </div>
  )
}

export default ServiceCard
