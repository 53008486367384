import React from "react"
import {mediaPageData} from "../../commons"
import Layout from "../../components/layout"
import Seo from "../../components/seo";
import Banner from "./Components/Banner/Banner";
import ServiceCard from "./Components/ServiceCard/ServiceCard";
import ImageLoop from "./Components/ImageLoop/ImageLoop";
import './index.scss'
import PutinCard from "./Components/PutinCard/PutinCard";
import AdvantagesCard from "./Components/AdvantagesCard/AdvantagesCard";
import pathJson from "../../data/common/path.json"

const MediaPage = ({location}) => {
    const {
        bannerData,
        serviceData,
        sponsorDataFirstLine,
        sponsorDataSecondLine,
        sponsorDataThirdLine,
        processData,
        brandCustomerData,
        salesData
    } = mediaPageData

    return (
        <Layout>
            <Seo title={pathJson.ONE_CLICK_PLACEMENT.title}/>

            {/* 轮播 */}
            <Banner
                title={bannerData.title}
                description={bannerData.description}
                image="/pages/media/bannerpic.png"
                background="/pages/media/background.png"
            />

                        {/* 投放流程 */}
                        <section className="media_fourth_content">
                <div style={{width: '100%', textAlign: 'center', fontSize: '24px',}}>
                    一键代发流程
                </div>
                <PutinCard data={processData}/>


                <div className="myCard" style={{
                    backgroundImage: `url(/pages/media/card/background.png)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                    <div className="card_title"></div>
                    <div className="card_conten">
                        {brandCustomerData.map(v => (
                            <div className="card_box">
                                <div className="card_num">{v.numText}</div>
                                <div className="card_name">{v.title}</div>
                                <div className="card_msg">{v.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* 平台服务 */}
            <section className="media_second_content">
                <div style={{width: '100%', textAlign: 'center', fontSize: '24px',}}>
                    平台功能
                </div>
                <ServiceCard data={serviceData} className={'service'}></ServiceCard>
            </section>

            {/* 合作伙伴 */}
            <section className="media_third_content">
                <div style={{width: '100%', textAlign: 'center', fontSize: '24px',}}>
                    媒体资源
                </div>
                <div className="scrolling_animation">
                    <ImageLoop
                        list={sponsorDataFirstLine}
                        className={'roll1'}
                    />
                    <ImageLoop
                        list={sponsorDataSecondLine}
                        className={'roll2'}
                    />
                    <ImageLoop
                        list={sponsorDataThirdLine}
                        className={'roll1'}
                    />
                </div>

            </section>



            {/* 软文营销优势 */}
            <section className="media_fifth_content">
                <div style={{width: '100%', textAlign: 'center', fontSize: '24px'}}>
                    新媒体内容营销优势
                </div>

                <AdvantagesCard data={salesData}/>

            </section>


        </Layout>
    )
}

export default MediaPage

export const Head = () => <title>一键代发 | 里德笔记</title>
