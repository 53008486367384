import React from "react"
import './AdvantagesCard.scss'
const AdvantagesCard = ({ data }) => {
  return (
    <div className="adv_card">
        {
            data?.map((v,i)=>(
                <div key={i} className="adv_box">
                    <img className="title_img" src={v.imgPath} alt="" />
                    <div className="adv_cont">
                        <div className="adv_title"><img style={{ marginRight: '5px' }} width={20} src={v.iconPath} /><div>{v.title}</div></div>
                        <div className="adv_msg">{v.description}</div>
                    </div>
                </div>
            ))
        }
    </div>
  )
}

export default AdvantagesCard
