import React from "react"
import './PutinCard.scss'
const PutinCard = ({ data}) => {
  return (
    <div className="putin_card">
        {
           data?.map(v=>(
            !(v.arrow) ? ( 
            <div className="putin_content">
                <div className="putin_reallycontent_img">
                    <img width={40} src={v.iconPath} />
                </div>
                <div className="putin_reallycontent_name">
                  {
                    v.pathName ? <a className="mediaA" onClick={()=> window.open(v.pathName)}>{v.title}</a> : v.title
                  }
                </div>
            </div>
            ) : ( 
             <div className="putin_content right_content">
                <div className="putin_falsecontent_img">
                    <img width={20} src='/pages/media/right.png' />
                </div>
             </div>
            )
           ))
        }
    </div>
  )
}

export default PutinCard
